import httpService from '../../../helpers/http.service'

export default function (value) {
    let path = value;
    return  {
        getByHolder(id, params) {
            return httpService.get(`holders/${id}/${path}`, {params})
        },
        delete(id) {
            return   httpService.delete(`holders/${path}/${id}`);
        },
        save(params, id = null) {
            if(id) {
                return httpService.put(`holders/${path}/${id}`, params);
            }
            return httpService.post(`holders/${path}`, params);
        }
    }

}
